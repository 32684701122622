import axios from "axios";
import { GlobalConfig } from './Config';
import { LoginTypes } from '../Types/LoginTyps';



export const userLogin = async (data: LoginTypes) => {
    try {
        const response = await axios.post(`${GlobalConfig.URL}/member/login`, data,{
        
        });

        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};