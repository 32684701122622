import { useState, useRef, useEffect } from 'react';
import { Divider } from 'primereact/divider';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useStore } from '../../hooks/Store';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { GetDepositReport, GetMemberPorfile } from '../../hooks/UserDetail'
import { MemberProfileDetailsTyps, DepositReportTypes } from '../../Types/UserDatailTypes'
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCopy } from '@fortawesome/free-solid-svg-icons';
import { GetPromotion } from '../../hooks/Promotion';
import { PromoType } from '../../Types/PromoDeatailTeyps';
import { DepositTypes } from '../../Types/DepositTypes';
import { DepositSubmit } from '../../hooks/Deposit';
import ImageUploadField from '../../component/ImageUploadField';
import { useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';

const socket = io('https://io-sport-ufa.lnwthai.com');



const Deposit = () => {
  const { username } = useStore();
  const [memberPorfile, setMemberPorfile] = useState<MemberProfileDetailsTyps>();
  const [depositReport, setDepositReport] = useState<DepositReportTypes[]>();
  const [promoDetail, setPromoDetail] = useState<PromoType[]>();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const navigate = useNavigate();

  const handleFileChange = (file: File | null) => {
    setSelectedFile(file);
  };

  const handleCopy = () => {
    if (toast.current) {
      toast.current.show({ severity: 'success', summary: 'Copied', detail: 'Account number copied to clipboard!' });
    }
  };

  const toast = useRef<Toast>(null);

  const fetchMemberProfile = async () => {
    const resProfile = await GetMemberPorfile(username);
    setMemberPorfile(resProfile);
  }

  const fetchDepositReport = async () => {
    const res = await GetDepositReport(username);
    setDepositReport(res);

  }

  const fetchPromotion = async () => {
    const respro = await GetPromotion();
    setPromoDetail(respro);
  }

  useEffect(() => {
    fetchMemberProfile();
    fetchDepositReport();
    fetchPromotion();
  }, []);


  const checkIO = async () => {
    try {
      console.log(1111);
      socket.emit('dep');
      console.log(2222);
      // Cleanup on unmount
      return () => {
        socket.off('dep');
      };
    } catch (error) {
      console.error("IO ERROR", error);
    }
  };

  useEffect(() => {
    checkIO();

  }, []);


  const { register, handleSubmit, control, reset, formState: { errors } } = useForm({
    defaultValues: {
      slip: "",
      username: username,
      amount: "",
      proId: null,
    },
  });

  const onSubmit: SubmitHandler<DepositTypes> = async (formData) => {
    const { username, amount, proId, slip } = formData;
    const formPayload = new FormData();
    // console.log ("slip",slip)

    formPayload.append('username', username);
    formPayload.append('amount', amount);
    if (proId !== null) {
      formPayload.append('proId', proId.toString());
    }

    if (selectedFile) {
      formPayload.append('slip', selectedFile);
    }

    try {
      const resSubmit = await DepositSubmit(formPayload);

      // console.log("Submission Response:", resSubmit);

      // Check if submission was not successful and show a toast with the message
      if (resSubmit.success === false) {
        if (toast.current) {
          toast.current.show({
            severity: 'error',
            summary: 'Submission Error',
            detail: resSubmit.message || 'There was an error with your submission.',
            life: 2000
          });
        }
      } else {
        if (toast.current) {
          toast.current.show({
            severity: 'success',
            summary: ' สำเร็จ',
            detail: 'ยืนยันการฝากเงินสำเร็จ',
            life: 2000
          });
          reset();
          setSelectedFile(null);
          fetchDepositReport();
          socket.emit('dep');
        }
      }
    } catch (error) {
      console.error("Submission Error:", error);
      if (toast.current) {
        toast.current.show({
          severity: 'error',
          summary: 'เกิดข้อผิดพลาด',
          detail: 'ไฟล์สลิปต้องเป็นไฟล์ JPG,JPEG,PNG,GIF เท่านั้น',
          life: 2000
        });
      }
    }
  };


  const formatDate = (value: string) => { // Specify the type for `value`
    if (!value) return 'N/A';
    const date = new Date(value);
    const options: Intl.DateTimeFormatOptions = { // Explicitly type `options`
      year: 'numeric', // Use 'numeric' instead of string
      month: 'long', // 'long' is a valid value for month
      day: 'numeric', // Use 'numeric' instead of string
      hour: '2-digit', // Use '2-digit' which is a valid value
      minute: '2-digit', // Use '2-digit' which is a valid value
      second: '2-digit', // Use '2-digit' which is a valid value
      hour12: false
    };
    return new Intl.DateTimeFormat('th-TH', options).format(date);
  };
  const statusBodyTemplate = (rowData: any) => {
    let statusLabel;
    switch (rowData.status) {
      case 0:
        return <span className='text-xs text-white px-2 rounded-full bg-orange-500'>รอดำเนินการ</span>;
      case 2:
        return <span className='text-xs text-white px-2 rounded-full bg-red-500'>ยอดฝากนี้ทำรายการแล้ว</span>;
      case 3:
        return <span className='text-xs text-white px-2 rounded-full bg-red-500'>ไม่พบยอด</span>;
      case 4:
        return <span className='text-xs text-white px-2 rounded-full bg-red-500'>ไม่แนบสลิป</span>;
      case 5:
        return <span className='text-xs text-white px-2 rounded-full bg-red-500'>แจ้งฝากไม่ตรงกับธนาคารที่ฝากเข้า</span>;
      case 6:
        return <span className='text-xs text-white px-2 rounded-full bg-red-500'>เลขบัญชีไม่ตรงกับที่ลงทะเบียน</span>;
      case 7:
        return <span className='text-xs text-white px-2 rounded-full bg-red-500'>ส่งหลักฐานการโอนทาง Line@</span>;
      case 8:
        return <span className='text-xs text-white px-2 rounded-full bg-red-500'>ฝากเงินขั้นต่ำกว่า 100 (ติดต่อทางLine@)</span>;
      default:
        return <span className='text-xs text-white px-2 rounded-full bg-green-500'>เรียบร้อยแล้ว</span>;
    }
    return statusLabel;
  };



  return (
    <>
      <Toast ref={toast} />
      <main className='mx-4 my-4 flex flex-col gap-2 min-h-full lg:items-center'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='flex flex-col border-yellow-500 border-2 rounded-xl p-4 bg-black/80 gap-2 '>
            <h1>รายละเอียดบัญชีฝาก</h1>
            <Divider />
            <div>
              <h1>ชื่อบัญชี : <span className='text-white'>{memberPorfile?.depositDetails.acctName}</span></h1>
              <h1>หมายเลขบัญชี : <span className='text-white'>{memberPorfile?.depositDetails.acctNo}</span> <CopyToClipboard text={memberPorfile?.depositDetails.acctNo || ''} onCopy={handleCopy}>
                <FontAwesomeIcon icon={faCopy} onCopy={handleCopy} />
              </CopyToClipboard>
              </h1>
              <h1>ธนาคาร : <span className='text-white'>{memberPorfile?.depositDetails.bankName}</span></h1>
            </div>
            <Divider />
            <h1 className=' underline'>แจ้งฝากเงิน</h1>
            <div className="flex flex-col gap-2">
              <label htmlFor="amount" className='text-yellow-500 text-sm'>จำนวนเงินที่ฝาก</label>
              <InputText
                placeholder='กรอกจำนวนเงินเป็น'
                className={`px-3 py-2 ${errors.amount ? 'p-invalid' : ''}`}
                {...register('amount', {
                  required: 'กรุณาใส่จำนวนเงินที่ต้องการถอน ขั้นต่ำ 200 บาท',
                  pattern: {
                    value: /^[0-9]+$/,
                    message: 'ต้องเป็นตัวเลขเท่านั้น'
                  }
                })}
              />
              {errors.amount && <span className="text-red-500">{errors.amount.message}</span>}
            </div>
            <div className="flex flex-col justify-content-center">
              <label htmlFor="amount" className='text-yellow-500 text-sm'>เลือกโปรโมชั่น</label>
              <Controller
                name="proId"
                control={control}
                render={({ field }) => (
                  <Dropdown
                    value={field.value}
                    onChange={(e) => field.onChange(e.value)}
                    options={promoDetail} // Use your promotions data as options
                    optionLabel="name" // Display the promotion name
                    optionValue="proId" // Send the promotion ID as the value
                    placeholder="เลือกโปรโมชั่น"
                    className={`w-full md:w-14rem lg:w-full ${errors.proId ? 'p-invalid' : ''}`}
                    pt={{
                      input: { className: 'p-2.5 w-full md:w-14rem' },

                    }}
                  />
                )}
              />
              {errors.proId && <span className="text-red-500">{errors.proId.message}</span>}
            </div>
            <div className="flex flex-col ">
              <Toast ref={toast}></Toast>
              <label htmlFor="amount" className='text-yellow-500 text-sm'> อัฟโหลดสลิป</label>
              <p className='text-yellow-200'> ** ไฟล์ภาพต้องเป็นนามสกุล jpeg,jpg,png,gif เท่าน้ัน และขนาดไฟล์ไม่เกิน 10mb</p>
              <Controller
                name="slip"
                control={control}
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <Controller
                    name="slip"
                    control={control}
                    render={({ field: { onChange, ref } }) => (
                      <ImageUploadField
                        onChange={handleFileChange}
                        value={selectedFile}

                      />
                    )}
                  />

                )}
              />
              {errors.slip && <span className="text-red-500">{errors.slip.message}</span>}
            </div>
            <div className='flex flex-col my-2'>
              <Button label='ยืนยันการเติมเงิน' className='bg-yellow-500 border-none text-black' size='small' />
            </div>
          </div>
          <div className='flex flex-col border-yellow-500 border-2 rounded-xl p-4 bg-black/80 gap-2 my-4'>
            <h1>ประวัติการฝาก</h1>
            <Divider />
            <DataTable value={depositReport} >
              <Column className='text-xs ' field="createdAt" header="วันที่ : เวลา" body={(rowData) => formatDate(rowData.createdAt)}></Column>
              <Column className='text-xs ' field="amount" header="จำนวน"></Column>
              <Column field="status" header="สถานะ" body={statusBodyTemplate}></Column>

            </DataTable>
          </div>
        </form>
        <div className='flex gap-2 justify-end lg:justify-center' onClick={() => navigate('/dashboard')}>
          <h1><FontAwesomeIcon icon={faArrowLeft} className='text-2xl' /></h1>
          <h1>ย้อนกลับ</h1>
        </div>
      </main>
    </>
  );
};

export default Deposit;
