import { Outlet } from "react-router-dom"
import TopNav from "./TopNav"

const Backoffice = () => {
  return (
    <>
      <div className="">
        <TopNav />
      </div>
      <div className="py-4">
        <Outlet />
      </div>



    </>
  )
}

export default Backoffice