import { useState, useEffect } from "react";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";
import { useNavigate } from "react-router-dom";
import { PromoType } from "../../Types/PromoDeatailTeyps";
import { GetPromotion } from "../../hooks/Promotion";

const Promotion = () => {
    const [promotion, setPromotion] = useState<PromoType[]>();
    const navigate = useNavigate();

    const fetchPromotion = async () => {
        const respro = await GetPromotion();
        setPromotion(respro);
    }

    useEffect(() => {
        fetchPromotion();
    }, []);

    return (
        <>
            <main className='mx-4 my-4 flex flex-col gap-2'>
                <div className='flex flex-col border-yellow-500 border-2 rounded-xl p-4 bg-black/80 gap-2'>
                    <h1>โบนัสและโปรโมชั่น</h1>
                    <p>ลูกค้าสามารถเลือกรับโปรโมชั่นหรือโบนัสได้ที่หน้าฝากเงิน</p>
                    <Divider />
                    <div className="flex flex-col gap-2 lg:grid lg:grid-cols-4 text-sm lg:text-sm text-gray-500">
                        {promotion && promotion.map((promo, index) => (
                            <Card title={promo.name} key={index}>
                                <div dangerouslySetInnerHTML={{ __html: promo.comment }} />
                            </Card>
                        ))}
                    </div>
                </div>
                <div className="flex gap-2 justify-center" onClick={() => navigate('/dashboard')}>
                    <h1><FontAwesomeIcon icon={faArrowLeft} /></h1>
                    <h1><span>ย้อนกลับ</span></h1>
                </div>
            </main>
        </>
    )
}

export default Promotion;
