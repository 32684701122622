import axios from "axios";
import { GlobalConfig } from './Config';
import { RegisterDetailTeyps } from "../Types/RegisterTypes";
import { OTPDetailTeyps } from "../Types/OtpType";


export const GetallBank = async () => {
    try {
        const response = await axios.get(`${GlobalConfig.URL}/member/bank`,{});

        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export const RegisterSubmit = async (data:RegisterDetailTeyps) => {
    try {
        const response = await axios.post(`${GlobalConfig.URL}/member/register`,data,{});

        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export const SubmitOTP = async (data:OTPDetailTeyps ,accessToken:string | undefined) => {
    try {
        const response = await axios.post(`${GlobalConfig.URL}/member/verify-otp`,data,{
            headers: {
                Authorization: `Bearer ${accessToken}`
              }
        });

        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

