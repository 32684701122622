import { BrowserRouter, Routes ,Route} from "react-router-dom";
import "./App.css";
import Backoffice from "./layout/Backoffice";
import ClientLayout from "./layout/ClientLayout";
import Home from "./page/client/Home";
import Register from "./page/client/Register";
import Registers from "./page/client/Registers";
import authRoute from "./Route";




function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Backoffice/>}>
         {/* <Route path="/dashboard"  element={<Dashboard />}/> */}
         {authRoute.map(route => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        </Route>
        <Route element={<ClientLayout/>}>
         <Route path="/"  element={<Home />}/>
         <Route path="/register/:af?"  element={<Register />}/>
         <Route path="/registers/:af?"  element={<Registers />}/>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
