import axios from "axios";
import { GlobalConfig } from './Config';
import { WithdrawTypes } from "../Types/WithdrawType";
import { useStore } from "./Store";

export const WithdrawSubmit = async (data:WithdrawTypes) => {
    try {
        const response = await axios.post(`${GlobalConfig.URL}/member/withdraw`, data,{
            headers: {
                Authorization: `Bearer ${useStore.getState().accessToken}`
              }
        });

        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};