import { useState, useEffect, useRef } from 'react';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { Button } from 'primereact/button';
import { GetallBank, RegisterSubmit, SubmitOTP } from '../../hooks/Register';
import { RegisterDetailTeyps } from '../../Types/RegisterTypes';
import { GlobalConfig } from '../../hooks/Config';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { OTPDetailTeyps } from '../../Types/OtpType';
import { Toast } from 'primereact/toast';
import ReCAPTCHA from "react-google-recaptcha";
import LogoLnw from "../../Images/Lnw_logo400x100_t_0.png"
// import { useLocation } from 'react-router-dom';
import { RenderAgent } from '../../hooks/CheckAgent';
import { Card } from 'primereact/card';



interface bankType {
    initial: string;
    name: string;
}

const Registers = () => {
    const [bank, setBank] = useState<bankType[]>([]);
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);
    const [newuername, setNewusername] = useState();
    const toast = useRef<Toast>(null);
    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const [recaptchacode, setRecaptchacode] = useState<string | null>();
    const [accessToken, setAccessToken] = useState<string>();
    const [agentName, setAgentName] = useState<string>();
    const { af } = useParams();

    const linktosupport = () => {
        window.open(GlobalConfig.LINESUPPORT, '_blank', 'noopener,noreferrer');
      };



    const { register, handleSubmit, formState: { errors }, control } = useForm({
        defaultValues: {
            firstname: '',
            lastname: '',
            password: '',
            mobile: '',
            lineId: '',
            adviser: '',
            bankName: '',
            acctNo: '',
            recaptcha: recaptchacode,
        }
    });

    const { register: register2, handleSubmit: handleSubmit2 } = useForm<OTPDetailTeyps>({
        defaultValues: {
            username: newuername,
            otp: '',
        }
    });

    const fetchBank = async () => {
        const resBank = await GetallBank();
        setBank(resBank);
    }


    const checkAffRender = async (af: string | undefined) => {
        const ResRender = await RenderAgent(af);
        // console.log("rest Render:", ResRender)
        setAgentName(ResRender.agentUsername);
    };


    useEffect(() => {
        checkAffRender(af);
        fetchBank();
    }, []);


    const submitRegister: SubmitHandler<RegisterDetailTeyps> = async (data) => {
        try {
            const submissionData = {
                ...data,
                recaptcha: recaptchacode,
                adviser: agentName

            };

            const resRegis = await RegisterSubmit(submissionData);
            // console.log("resRegis", resRegis);
            // console.log("Submit Data:", data);
            // console.log("submissionData Data:", submissionData);
            if (resRegis.success === true) {
                setVisible(true);
                setNewusername(resRegis.username);
                setAccessToken(resRegis.accessToken);

            } else {

            }
        } catch (error) {
            console.error('An error occurred during registration', error);
            toast.current?.show({ severity: 'error', summary: 'Error', detail: `เกิดข้อผิดพลาดกรุณาตรวจสอบขอมูลให้ถูกต้อง`, life: 3000 });

        }
    };

    const onReCAPTCHAChange = (captchaCode: string | null) => {
        setRecaptchacode(captchaCode);
        if (!captchaCode === null) {
            toast.current?.show({ severity: 'error', summary: 'สำเร็จ', detail: "กรุณากรอก Captca", life: 3000 });
        }
    };

    const submitOTP: SubmitHandler<OTPDetailTeyps> = async (data) => {
        const resOTP = await SubmitOTP(data, accessToken);
        // console.log("OTP REs",resOTP);
        if (resOTP.success === true) {
            toast.current?.show({ severity: 'success', summary: 'สำเร็จ', detail: resOTP.message, life: 3000 });
            navigate('/');
        } else {
            toast.current?.show({ severity: 'error', summary: 'Error Message', detail: 'OTP verification failed', life: 3000 });
        }
    };

    ///// step render ////
    const [currentStep, setCurrentStep] = useState(1);
    const totalSteps = 4;

    const nextStep = () => {
        if (currentStep < totalSteps) {
            setCurrentStep(currentStep + 1);
        }
    };

    const prevStep = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
        }
    };

    const StepOne = () => {
        return (
            <div>
                <div className="flex flex-col gap-2 p-input-icon-left">
                    <label htmlFor="phone" className='text-yellow-500 text-sm'>หมายเลขโทรศัพท์</label>
                    <InputText placeholder='หมายเลขโทรศัพท์' className="px-3 py-2"
                        {...register("mobile", { required: true })}
                    />
                </div>
                <div className='my-4 flex flex-col'>
                    <Button label='ถัดไป' size='small' rounded onClick={nextStep} />
                </div>

            </div>
        );
    };

    const StepTwo = () => {
        return (
            <div>
                <div className="flex flex-col justify-content-center">
                    <label htmlFor="amount" className='text-yellow-500 text-sm'>เลือกโปรโมชั่น</label>
                    <Controller
                        name="bankName"
                        control={control}
                        render={({ field }) => (
                            <Dropdown
                                value={field.value}
                                onChange={(e) => field.onChange(e.value)}
                                options={bank} // Use your promotions data as options
                                optionLabel="name" // Display the promotion name
                                optionValue="name" // Send the promotion ID as the value
                                placeholder="เลือกธนาคาร"
                                className='mt-2'
                                pt={{
                                    input: { className: 'w-full py-2.5 px-2' },
                                }}
                            />
                        )}
                    />
                    {errors.bankName && <span className="text-red-500">{errors.bankName.message}</span>}
                </div>

                <div className="flex flex-col gap-2">
                    <label htmlFor="accountNumber" className='text-yellow-500 text-sm'>หมายเลขบัญชี</label>
                    <InputText placeholder='หมายเลขบัญชี 10  หลัก' className="px-3 py-2"
                        {...register('acctNo', {
                            required: true,
                            minLength: {
                                value: 10,
                                message: "หมายเลขบัญชี 10 หลัก"
                            },
                        })}
                    />
                    {errors.acctNo && <span className="text-red-500">{errors.acctNo.message}</span>}
                </div>
                <div className='grid grid-cols-2 my-4 gap-2'>
                    <Button label='ย้อนกลับ' size='small' rounded severity='danger' onClick={prevStep} />
                    <Button label='ถัดไป' size='small' rounded onClick={nextStep} />
                </div>

            </div>
        );
    };

    const StepThree = () => {
        return (
            <div>
                <div className="flex flex-col gap-2">
                    <label htmlFor="name" className='text-yellow-500 text-sm'>ชื่อ</label>
                    <InputText placeholder='กรุณากรอกชื่อ' className="px-3 py-2"
                        {...register("firstname", { required: true })}
                    />
                </div>
                <div className="flex flex-col gap-2">
                    <label htmlFor="surname" className='text-yellow-500 text-sm'>นามสกุล</label>
                    <InputText placeholder='กรุณากรอกนามสกุล' className="px-3 py-2"
                        {...register("lastname", { required: true })}
                    />
                </div>
                <div className='grid grid-cols-2 my-4 gap-2'>
                    <Button label='ย้อนกลับ' size='small' rounded severity='danger' onClick={prevStep} />
                    <Button label='ถัดไป' size='small' rounded onClick={nextStep} />
                </div>
            </div>
        );
    };

    const StepFour = () => {
        return (
            <div>
                <div className="flex flex-col gap-2">
                    <label htmlFor="password" className='text-yellow-500 text-sm'>รหัสผ่าน</label>
                    <InputText placeholder='รหัสผ่านเช่น Lnw12345@' className="px-3 py-2"
                        {...register('password', {
                            required: true,
                            minLength: {
                                value: 7,
                                message: "ความยาวอย่างน้อย 7 ตัวอักษร"
                            },
                            pattern: {
                                value: /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{7,}$/,
                                message: "ต้องประกอบด้วยตัวพิมพ์ใหญ่ 1 ตัว ตัวเลข 1 ตัว อักขระพิเศษ 1 ตัว เช่น Lnw12345@' "
                            }
                        })}
                    />
                    {errors.password && <span className="text-red-500">{errors.password.message}</span>}
                </div>

                <div className="flex flex-col gap-2">
                    <label htmlFor="lineId" className='text-yellow-500 text-sm'>Line ID</label>
                    <InputText placeholder='หากไม่มีให้ใส่เบอร์โทร' className="px-3 py-2"
                        {...register('lineId', {
                            required: true, pattern: {
                                value: /^[0-9]+$/,
                                message: "ต้องเป็นตัวเลขเท่านั้น"
                            }
                        })}
                    />
                </div>
                <div className="flex flex-col gap-2">
                    <label htmlFor="referral" className='text-yellow-500 text-sm'>ผู้แนะนำ</label>
                    <InputText placeholder='ผู้แนะนำ' className="px-3 py-2" disabled value={agentName}
                    />
                </div>

                <div className=' my-2 flex flex-col gap-2'>
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6Ld4mB8TAAAAAECXP-18qwV89_YCGQguYNappGBg"
                        onChange={onReCAPTCHAChange}

                    />
                    <p className='text-center lg:text-xs'>กรุณาเช็คถูกเพื่อยืนยันว่าท่านไม่ใช่ระบบอัติโนมัติ</p>
                    <Button label='สมัครสมาชิก' size='small' rounded  className='bg-yellow-500 border-none ' type='submit' disabled={!recaptchacode} />
                </div>
                <div className='flex flex-col my-4 gap-2'>
                    <Button label='ย้อนกลับ' size='small' rounded severity='danger' onClick={prevStep} />
                </div>
            </div>
        );
    };

    //   const handleSubmit = () => {
    //     // Handle final submission of all form data
    //   };


    const renderStep = () => {
        switch (currentStep) {
            case 1:
                return <StepOne />;
            case 2:
                return <StepTwo />;
            case 3:
                return <StepThree />;
            case 4:
                return <StepFour />;
            default:
                return null;
        }
    };

    return (
        <>
            <Toast ref={toast} />
            <main className='p-4 h-screen flex flex-col justify-center lg:h-screen lg:items-center'>
                <form onSubmit={handleSubmit(submitRegister)}>
                    <Card className=' p-4 '>
                        <div className='flex flex-col justify-center lg:w-60'>
                            <h1 className='text-3xl text-center'>LOGO</h1>
                            <h2 className='text-center text-gray-600'>สมัครสมาชิก</h2>
                            <h1 className='text-center text-gray-400'>({currentStep}/4)</h1>
                        </div>
                        {renderStep()}
                        <div className='flex flex-col gap-2'>
                            <p className='text-black text-center'>หรือ</p>
                            <p className='text-black text-center'>มีบัญชีอยู่แล้ว <span className='text-red-500 underline' onClick={() => navigate('/')}>เข้าสู่ระบบ</span></p>
                            <Button label='ติดต่อเรา' size='small' rounded severity='success'  onClick={linktosupport}/>
                        </div>
                    </Card>
                </form>

                <div className="card flex justify-content-center">
                    <Dialog header="ยืนยันOTP" visible={visible} style={{ width: '80vw' }} onHide={() => setVisible(false)}>
                        <form onSubmit={handleSubmit2(submitOTP)} >
                            <div className='flex flex-col gap-2 mt-4'>
                                <InputText placeholder='username' defaultValue={newuername}
                                    {...register2('username')}
                                />
                                <InputText placeholder='OTP'
                                    {...register2('otp')}
                                />
                                <Button label='ยืนยัน OTP' size='small' type='submit' />
                            </div>
                        </form>
                    </Dialog>
                </div>

            </main>


        </>
    );
}

export default Registers;
