import axios from "axios";
import { GlobalConfig } from './Config';
import { useStore } from "./Store";


export const FreeCreditDetail = async (username :string) => {
    try {
        const response = await axios.get(`${GlobalConfig.URL}/member/freecredit?username=${username}`, {
            headers: {
                Authorization: `Bearer ${useStore.getState().accessToken}`
            }
        });

        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};


export const GetFreeCredit = async (username: string) => {
    try {
        const response = await axios.post(`${GlobalConfig.URL}/member/freecredit`, {
            username : username,
        }, {
            headers: {
                Authorization: `Bearer ${useStore.getState().accessToken}`
            }
        });

        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};
