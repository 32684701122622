import { create } from "zustand";
import { persist } from 'zustand/middleware'

type Store = {
    accessToken: string;
    username: string;
    // roleType: string;
    // roleName: string;
    // userId: string;
  
    setAccessToken: (token: string) => void;
    setUsername: (name: string) => void;
    // setRoleType: (name: string) => void;
    // setRoleName: (name: string) => void;
    // setuserId: (userId: string) => void;
   
    clearSession: () => void;
  };
  
  export const useStore = create(
    persist<Store>(
      (set, get) => ({
        accessToken: '',
        username: '',
        // roleType: '',
        // roleName: '',
        // userId:'',
    
        
        setAccessToken: (token: string) => set({ accessToken: token }),
        setUsername: (name: string) => set({ username: name }),
        // setRoleType: (roleType: string) => set({ roleType: roleType }),
        // setRoleName: (roleName: string) => set({ roleName: roleName }),
        // setuserId: (userId: string) => set({ userId: userId }),
  
        clearSession: () => set({ username: '',accessToken:''}),
      }),
      {
        name: 'lnw-store', // unique name
        getStorage: () => localStorage, // (optional) by default the 'localStorage' is used
      }
    )
  )
  