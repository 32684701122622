import axios from "axios";
import { GlobalConfig } from './Config';
import { useStore } from "./Store";

export const DepositSubmit = async (formData: FormData) => {
    try {
        const response = await axios.post(`${GlobalConfig.URL}/member/deposit`, formData, {
            headers: {
                Authorization: `Bearer ${useStore.getState().accessToken}`
              }
        });

        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};