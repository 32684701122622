import { useState, useEffect, useRef } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { GetMemberPorfile } from '../../hooks/UserDetail'
import { MemberProfileDetailsTyps, DepositReportTypes } from '../../Types/UserDatailTypes'
import { useStore } from '../../hooks/Store';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { GetWithDrawReport } from '../../hooks/UserDetail';
import { Toast } from 'primereact/toast';
import { Divider } from "primereact/divider"
import { Button } from 'primereact/button';
import { WithdrawTypes } from '../../Types/WithdrawType';
import { WithdrawSubmit } from '../../hooks/Withdraw';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import io from 'socket.io-client';

const socket = io('https://io-sport-ufa.lnwthai.com'); 


const Withdraw = () => {
  const { username } = useStore();
  const [memberPorfile, setMemberPorfile] = useState<MemberProfileDetailsTyps>();
  const [withDrawReport, setWithDrawReport] = useState<DepositReportTypes[]>();
  const toast = useRef<Toast>(null);
  const navigate = useNavigate();

  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      username: username,
      amount: "",
    },
  });


  const fetchMemberProfile = async () => {
    const resProfile = await GetMemberPorfile(username);
    setMemberPorfile(resProfile);
  }


  const fetchWithDrawReport = async () => {
    const res = await GetWithDrawReport(username);
    setWithDrawReport(res);

  }

  useEffect(() => {
    fetchMemberProfile();
    fetchWithDrawReport();

    socket.on('wdr', (data: any) => {
      console.log("socketDATA",data);
    });

      // Cleanup on unmount
      return () => {
        socket.off('wdr');
      };
  }, []);

  const onSubmit: SubmitHandler<WithdrawTypes> = async (data) => {
    const resWithDraw = await WithdrawSubmit(data);
    // console.log("WithDrawRes", resWithDraw);
    // console.log(data);

    if (resWithDraw.success === false) {
      if (toast.current) {
        toast.current.show({
          severity: 'error',
          summary: 'Withdrawal Failed',
          detail: resWithDraw.message || 'An unexpected error occurred.',
          life: 3000
        });
      }
    } else {
      if (toast.current) {
        toast.current.show({
          severity: 'success',
          summary: 'ส่งคำสั่งสำเร็จ',
          detail: resWithDraw.message,
          life: 3000
        });
      }
      socket.emit('wdr');
      fetchWithDrawReport();
    }
  };


  const formatDate = (value: string) => { // Specify the type for `value`
    if (!value) return 'N/A';
    const date = new Date(value);
    const options: Intl.DateTimeFormatOptions = { // Explicitly type `options`
      year: 'numeric', // Use 'numeric' instead of string
      month: 'long', // 'long' is a valid value for month
      day: 'numeric', // Use 'numeric' instead of string
      hour: '2-digit', // Use '2-digit' which is a valid value
      minute: '2-digit', // Use '2-digit' which is a valid value
      second: '2-digit', // Use '2-digit' which is a valid value
      hour12: false
    };
    return new Intl.DateTimeFormat('th-TH', options).format(date);
  };

  const statusBodyTemplate = (rowData: any) => {
    let statusLabel;
    switch (rowData.status) {
      case 0:
        return <span className='text-xs text-white px-2 rounded-full bg-orange-500'>รอดำเนินการ</span>;
      case 2:
        return <span className='text-xs text-white px-2 rounded-full bg-red-500'>ถอนครบจำนวนครั้งต่อวัน กรุณาทำรายการใหม่พรุ่งนี้</span>;
      case 3:
        return <span className='text-xs text-white px-2 rounded-full bg-red-500'>ยอดเทิร์นโอเวอร์ไม่ถึง (ยกเลิกการถอน)</span>;
      case 4:
        return <span className='text-xs text-white px-2 rounded-full bg-red-500'>เลขบัญชีถอนไม่ถูกต้อง (ยกเลิกการถอน)</span>;
      case 5:
        return <span className='text-xs text-white px-2 rounded-full bg-red-500'>ธนาคารปรับปรุง (ยกเลิกการถอน)</span>;
      case 6:
        return <span className='text-xs text-white px-2 rounded-full bg-red-500'>ชื่อบัญชีไม่ตรงกับที่ลงทะเบียน (ยกเลิกการถอน)</span>;
      case 7:
        return <span className='text-xs text-white px-2 rounded-full bg-red-500'>ไม่มีรายการเล่น (ยกเลิกการถอน)</span>;
      case 8:
        return <span className='text-xs text-white px-2 rounded-full bg-red-500'>มียอดค้างเดิมพัน (ยกเลิกการถอน)</span>;
      case 9:
        return <span className='text-xs text-white px-2 rounded-full bg-red-500'>ยอดถอนมากกว่าเครดิตคงเหลือ (ยกเลิกการถอน)</span>;
      case 10:
        return <span className='text-xs text-white px-2 rounded-full bg-red-500'>มียอดค้างในคาสิโน (ยกเลิกการถอน)</span>;
      default:
        return <span className='text-xs text-white px-2 rounded-full bg-green-500'>เรียบร้อยแล้ว</span>;
    }
    return statusLabel;
  };


  return (
    <main className='mx-4 my-4 flex flex-col gap-2 lg:items-center'>
      <Toast ref={toast} />

      <form onSubmit={handleSubmit(onSubmit)}>

        <div className='flex flex-col  border-yellow-500 border-2 rounded-xl p-4 bg-black/80 gap-2 lg:w-[450px]'>
          <h1>แจ้งถอนเงิน</h1>
          <Divider />
          <h1>โอนเข้าบัญชี : <span className='text-white text-sm'>{memberPorfile?.bankName} | {memberPorfile?.acctNo}</span></h1>
          <div className="flex flex-col gap-2">
            <label htmlFor="amount" className='text-yellow-500 text-sm'>จำนวนที่ต้องการถอน (ขึ้นต่ำ 200 บาท)</label>
            <InputText
              placeholder='กรอกจำนวนเงินเป็น'
              className={`px-3 py-2 ${errors.amount ? 'p-invalid' : ''}`}
              {...register('amount', {
                required: 'Amount is required',
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'ต้องเป็นตัวเลขเท่านั้น'
                }
              })}
            />
            {errors.amount && <span className="text-red-500">{errors.amount.message}</span>}
          </div>
          <div className='flex flex-col'>
            <Button label='แจ้งถอนเงิน' severity="success" className='text-black' size='small' type='submit' />
          </div>
        </div>
        <div className='flex flex-col border-yellow-500 border-2 rounded-xl p-4 bg-black/80 gap-2 my-4'>
          <h1>ประวัติการฝาก</h1>
          <Divider />
          <DataTable value={withDrawReport} >
            <Column className='text-xs ' field="createdAt" header="วันที่ : เวลา" body={(rowData) => formatDate(rowData.createdAt)}></Column>
            <Column className='text-xs ' field="amount" header="จำนวน"></Column>
            <Column field="status" header="สถานะ" body={statusBodyTemplate}></Column>

          </DataTable>
        </div>
      </form>
      <div className='flex gap-2 justify-center' onClick={() => navigate('/dashboard')}>
        <h1><FontAwesomeIcon icon={faArrowLeft} /> </h1>
        <h1>ย้อนกลับ</h1>
      </div>
    </main>
  )
}

export default Withdraw