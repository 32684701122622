import axios from "axios";
import { GlobalConfig } from './Config';
import { useStore } from "./Store";


export const GetUserBalance = async (username: string) => {
    try {
        const response = await axios.get(`${GlobalConfig.URL}/member/balance?username=${username}`, {
            headers: {
                Authorization: `Bearer ${useStore.getState().accessToken}`
              }
        });

        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export const GetMemberPorfile = async (username: string) => {
    try {
        const response = await axios.get(`${GlobalConfig.URL}/member/profile?username=${username}`, {
            headers: {
                Authorization: `Bearer ${useStore.getState().accessToken}`
              }
        });

        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export const GetDepositReport = async (username: string) => {
    try {
        const response = await axios.get(`${GlobalConfig.URL}/member/transaction?username=${username}&type=deposit`, {
            headers: {
                Authorization: `Bearer ${useStore.getState().accessToken}`
              }
        });

        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export const GetWithDrawReport = async (username: string) => {
    try {
        const response = await axios.get(`${GlobalConfig.URL}/member/transaction?username=${username}&type=withdraw`, {
            headers: {
                Authorization: `Bearer ${useStore.getState().accessToken}`
              }
        });

        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};