import { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useStore } from '../../hooks/Store';
import { CreditDailType } from '../../Types/CreditDetailType';
import { FreeCreditDetail } from '../../hooks/FreeCredit';
import { Card } from 'primereact/card';
import moment from 'moment-timezone';


const FreeCreditReport = () => {
    const [freecredit, setFreecredit] = useState<CreditDailType[]>([]);
    const storeData = useStore();

    const fetchCreditDetail = async () => {
        const resFreeCredit = await FreeCreditDetail(storeData.username);
        setFreecredit(resFreeCredit);
    };

    useEffect(() => {
        fetchCreditDetail();
    }, []);

    const formattedCreatedAt = (createdAt: string) => {
        return moment.tz(createdAt, 'UTC').tz('Asia/Bangkok').format('DD-MM-YYYY');
    };

    const formattedUpdatedAt = (updatedAt: string) => {
        return moment.tz(updatedAt, 'UTC').tz('Asia/Bangkok').format('DD-MM-YYYY');
    };


    const statusTemplate = (rowData: CreditDailType) => {
        return (
            <p className='text-gray-500'>{rowData.status === "0" ? "ยังไม่ได้รับโบนัส" : "รับแล้ว"}</p>
        );
    };


    return (
        <div className='mx-4 flex flex-col gap-2 lg:items-center'>
            <h1 className='text-2xl underline font-bold'>ประวัติการรับ Free Credit</h1>
            <Card>
                <DataTable value={freecredit} responsiveLayout="scroll">
                    <Column field="id" header="ID" />
                    <Column field="username" header="Username" />
                    <Column field="turnover" header="Turnover" />
                    <Column field="total" header="จำนวน" />
                    <Column body={statusTemplate} header="Status" />
                    <Column field="start" header="เริ่มวันที่" />
                    <Column field="end" header="สินสุดวันที่" />
                    <Column body={formattedCreatedAt} header="เริ่มนับเมื่อ" />
                    <Column body={formattedUpdatedAt} header="อัฟเดทเมื่อ" />
                </DataTable>
            </Card>
        </div>

    );
};

export default FreeCreditReport;