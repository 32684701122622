import axios from "axios";
import { GlobalConfig } from './Config';

export const RenderAgent = async (af : string | undefined) => {
    try {
        const response = await axios.get(`${GlobalConfig.URL}/member/register?agentUsername=${af}`, {
            // headers: {
            //     Authorization: `Bearer ${useStore.getState().accessToken}`
            //   }
        });

        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};